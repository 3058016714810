import React, { Component } from 'react';
import './App.css';
import './components/custom'
import Intro from './components/Intro'
import ThankYou from './components/ThankYou'
import Q1 from './components/q1'
import Q2 from './components/q2'
import Q3 from './components/q3'
import Q4 from './components/q4'
class App extends Component {
  state = {
    place: 'home',
    name: '',
    location: '',
    services: [],
    favServPart: '',
    ministries: [],
    minImprove: '',
    hasKids: null,
    numKids: '',
    kidsMinImprove: '',
    kidAges: {
      'a': false,
      'b': false,
      'c': false,
      'd': false
    },
    conferences: [],
    conferenceImpact: '',
    email: '',
    agreement: false
  }
  api = 'https://api.yourflorencechurch.com' //http://localhost:3501
  sendData = (page, auth, data) => {
    this.alive = true
    return new Promise((res, rej) => {
      if (!page || !data) return rej('No Page Requested')
      let authorization
      if (auth) {
        authorization = `Bearer ${auth}`
      }
      let that = new XMLHttpRequest()
      that.open('POST', this.api + page, true)
      if (authorization) {
        that.setRequestHeader('authorization', authorization)
      }
      that.setRequestHeader('Content-Type', 'Application/JSON')
      that.onabort = (e) => {
        return rej(e)
      }
      that.onerror = (e) => {
        return rej(e)
      }
      that.onreadystatechange = () => {
        if (that.readyState === 4) {
          return res(JSON.parse(that.responseText))
        }
      }
      that.send(JSON.stringify(data))
    })
  }
  follow = (data) => {
    return new Promise((resolve, reject) => {
      if (!data.page || !data.target || !data.event) return reject('Requires any {page, target, event}')
      this.sendData('/tracking', null, {page: data.page, target: data.target, event: data.event})
      .then(result => this.validate(result)).then(() => { return resolve(true) }).catch(e => { return reject(e) })
    })
  }
  validate = (a) => {
    if (a.error !== false) throw new Error(`ERROR: ${a.message}`)
    return a
  }
  sub = (e) => {
    const warn = e.target?.parentElement?.parentElement?.querySelector('.warn')
    let data = {...this.state}
    this.sendData('/submissionA', null, data).then(result => this.validate(result)).then(result => {
      sessionStorage.setItem('id', result._id)
      this.setState({place: 'thank-you'})
    }).catch(er => {
      if (warn) warn.innerHTML = er
    })
  }
  updateEmail = (e) => {
    return new Promise((res, rej) => {
      let id = sessionStorage.getItem('id')
      if (!id || id === 'undefined') {
        return rej('Issue with prior entry, please reload and try again.')
      }
      let data = {
        email: this.state.email,
        id: id
      }
      this.sendData('/submissionB', null, data).then(result => this.validate(result)).then(() => res(true)).catch(er => rej(er))
    })
  }
  render() {
    this.props.source(this)
    return (
      <div className="App">
        <Intro place={this.state.place === 'home' ? 'zero' : 'hide'} name={this.state.name} />
        <Q1 place={this.state.place === 'q1' ? 'zero' : 'hide'} location={this.state.location} favServPart={this.state.favServPart} services={this.state.services} />
        <Q2 place={this.state.place === 'q2' ? 'zero' : 'hide'} location={this.state.location} ministries={this.state.ministries} minImprove={this.state.minImprove} />
        <Q3 place={this.state.place === 'q3' ? 'zero' : 'hide'} hasKids={this.state.hasKids} numKids={this.state.numKids} kidAges={this.state.kidAges} kidsMinImprove={this.state.kidsMinImprove}/>
        <Q4 place={this.state.place === 'q4' ? 'zero' : 'hide'} location={this.state.location} conferenceImpact={this.state.conferenceImpact} conferences={this.state.conferences} />
        <ThankYou place={this.state.place === 'thank-you' ? 'zero' : 'hide'} />
      </div>
    );
  }
}
export default App;