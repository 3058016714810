import React, {Component} from 'react'
class ThankYou extends Component {
    constructor(){
        super()
        this.state = {
            email: ''
        }
    }
    changeEmail = (e) => {
        window.APP.setState({email: e.target.value})
    }
    componentDidUpdate(){
        if (this.state.email !== this.props.email) this.setState({email: this.props.email})
    }
    componentDidMount(){
        this.setState({email: this.props.email})
    }
    render(){
        let q = 'question ' + this.props.place
        return (<div id="thankyou" className={q}>
            <div className="b1">
                <img className="headimg" src="https://influenced.link/static/lwquiz/lwcircle.png" alt="Living Word"></img>
            </div>
        <div className="b1">
            <h1>THANK YOU FOR TAKING OUR SURVEY</h1>
            <p className='p'>We appreciate you taking the time to complete our survey.<br></br>
            Thank you for being a part of our Living Word family!</p>
        </div>
        <br></br>
        <div id="frontstart" className="b1">
            <p>PLEASE ENTER YOUR EMAIL</p>
            <div className="warn"></div>
            <input type="text" onChange={this.changeEmail}></input>
            <div className="b2"><input id="agree" type="checkbox"></input><div style={{padding: '5px'}}></div><p>I agree to receive email updates and promotions.</p></div>
            <button onClick={(e) => {
                const input = e.target.parentElement.querySelector('input')
                const warn = e.target.parentElement.querySelector('.warn')
                let a = e.target?.parentElement?.querySelector('#agree')
                if (!a || a.checked !== true) return warn.innerHTML= 'You must check the box below'
                if (window.APP.state.email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(window.APP.state.email)) {
                    window.APP.updateEmail().then(() => {
                        input.classList.add('hidden')
                        warn.innerHTML = 'Thank you for your submission. You are being redirected to our homepage.'
                        setTimeout(() => {
                            window.location = 'https://livingwordonline.com'
                        }, 2000)
                        window.APP.setState({
                            place: 'home',
                            name: '',
                            location: '',
                            services: [],
                            favServPart: '',
                            ministries: [],
                            minImprove: '',
                            hasKids: null,
                            numKids: '',
                            kidsMinImprove: '',
                            kidAges: {
                              'a': false,
                              'b': false,
                              'c': false,
                              'd': false
                            },
                            conferences: [],
                            conferenceImpact: '',
                            email: '',
                            agreement: false
                          })
                    }).catch(e => {
                        warn.innerHTML = e
                    })
                } else if (window.APP.state.email && !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(window.APP.state.email)) {
                    e.target.parentElement.querySelector('.warn').innerHTML = 'Please enter a valid email address.'
                } else {
                    e.target.parentElement.querySelector('.warn').innerHTML = 'Please enter your email.'
                }
            }}>SUBMIT</button>
        </div>
        </div>)
    }
}
export default ThankYou