import React, {Component} from 'react'
class Q4 extends Component {
    constructor(){
        super()
        this.state = {
            location: '',
            conferenceImpact: '',
            conferences: []
        }
    }
    previous = () => {
        window.APP.setState({place: 'q3'})
    }
    componentDidUpdate(){
        if (this.state.location !== this.props.location) this.setState({location: this.props.location})
        if (this.state.conferenceImpact !== this.props.conferenceImpact) this.setState({conferenceImpact: this.props.conferenceImpact})
        if (this.props.conferences.length !== this.state.conferences.length) this.setState({conferences: this.props.conferences})
    }
    componentDidMount(){
        this.setState({location: this.props.location, conferences: this.props.conferences, conferenceImpact: this.props.conferenceImpact})
    }
    confer = (e) => {
        let conference = e.target.value
        if (!this.state.conferences.includes(conference)) {
            window.APP.setState({conferences: [...window.APP.state.conferences, conference]})
        } else {
            let min = [...window.APP.state.conferences].filter(m => m !== conference)
            window.APP.setState({conferences: min})
        }
    }
    impact = (e) => {
        window.APP.setState({conferenceImpact: e.target.value})
    }
    render(){
        let q = 'question ' + this.props.place
        let con = this.state.location ? window.Conferences.map((c, i) => {
            let u = this.state.conferences.includes(c) ? true : false
            return (<div className="b1" key={i}>
                <p className="caps green">{c}</p>
                <input type="checkbox" value={c} checked={u} onChange={this.confer}></input>
            </div>)
        }) : ''
        // if (!con && window.APP.state.place === 'q3') window.APP.setState({place: 'q1'})
        return (<div id="q4" className={q}>
            <div className="b1">
                <h1 className="green">Question 4</h1>
                <p className="green">Which conferences do you plan to attend throughout the year?</p>
                <div className="b2 wrap">{con}</div>
            </div>
            <div className="b1">
                <p className="green">Which conference do you find most impactful and why?</p>
                <textarea value={this.state.conferenceImpact} onChange={this.impact}></textarea>
            </div>
            <div className="b1">
                <div className="warn" style={{color: 'var(--light)'}}></div>
                <div className='b2s'>
                    <img onClick={this.previous} className='arrow leftarrow' alt="previous" src="https://influenced.link/static/lwquiz/greenarrow.png"></img>
                    <button onClick={window.APP.sub}>SUBMIT</button>
                </div>
            </div>
        </div>)
    }
}
export default Q4