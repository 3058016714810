import React, {Component} from 'react'
class Q3 extends Component {
    constructor(){
        super()
        this.state = {
            hasKids: null,
            numKids: '',
            kidAges: {},
            kidsMinImprove: ''
        }
        this.ag = [{letter: 'a', value: '0-4'}, {letter: 'b', value: '5-11'}, {letter: 'c', value: '12-15'}, {letter: 'd', value: '16-18'}]
    }
    previous = () => {
        window.APP.setState({place: 'q2'})
    }
    next = () => {
        window.APP.setState({place: 'q4'})
    }
    kidsCheck = (e) => {
        window.APP.setState({hasKids: e.target.value})
    }
    setKids(){
        let kc = Array.from(document.querySelectorAll('.kC'))
        kc.forEach(k => {
            if (k.value !== window.APP.state.hasKids) {
                k.checked = false
            } else {
                k.checked = true
            }
        })
    }
    ageKids = (e) => {
        let value = e.target.value
        let checked = e.target.checked
        let n = Object.fromEntries(Object.entries({...this.state.kidAges}).map(([key, val]) => {
            if (key === value) val = checked
            return [key, val]
        }))
        window.APP.setState({kidAges: n})
    }
    quantKids = (e) => {
        window.APP.setState({numKids: e.target.value})
    }
    kidsImprove = (e) => {
        window.APP.setState({kidsMinImprove: e.target.value})
    }
    componentDidMount(){
        this.setState({hasKids: this.props.hasKids, numKids: this.props.numKids, kidAges: this.props.kidAges, kidsMinImprove: this.props.kidsMinImprove})
        this.setKids()
    }
    componentDidUpdate() {
        if (this.state.hasKids !== this.props.hasKids) {
            this.setState({hasKids: this.props.hasKids})
            this.setKids()
        }
        if (this.state.numKids !== this.props.numKids) this.setState({numKids: this.props.numKids})
        if (this.state.kidAges !== this.props.kidAges) this.setState({kidAges: this.props.kidAges})
        if (this.state.kidsMinImprove !== this.props.kidsMinImprove) this.setState({kidsMinImprove: this.props.kidsMinImprove})
    }
    render(){
        let q = 'question ' + this.props.place
        let g = this.state.hasKids === 'yes' ? 'b1' : 'hidden'
        let f = this.state.numKids ? 'b1' : 'hidden'
        let ages = this.ag.map((a, i) => {
            return (<div className="b1" key={i}>
                <p className="kids caps">{a.value}</p>
                <input type="checkbox" value={a.letter} onChange={this.ageKids}></input> 
            </div>)
        })
        return (<div id="q3" className={q}>
            <div className="b1">
                <h1>Question 3</h1>
                <p>Do you have kids?</p>
                <div className="b2s" style={{width: '60%'}}>
                    <div className="b1">
                        <p className="caps">YES</p>
                        <input type="radio" value="yes" className="kC" onChange={this.kidsCheck}></input>
                    </div>
                    <div className="b1">
                        <p className="caps">NO</p>
                        <input type="radio" value="no" className="kC" onChange={this.kidsCheck}></input>
                    </div>
                </div>
            </div>
            <div className={g} style={{animation: '300ms ease-in slideup'}}>
                <p>How Many?</p>
                <input type="text" value={this.state.numKids} onChange={this.quantKids}></input>
            </div>
            <div className={f} style={{animation: '300ms ease-in slideup'}}>
                <p>Which Ages?</p>
                <div className="b2s">
                    {ages}
                </div>
            </div>
            <div className={f} style={{animation: '300ms ease-in slideup'}}>
                <p>What improvements would you like to see in the children's ministries?</p>
                <textarea value={this.state.kidsMinImprove} onChange={this.kidsImprove}></textarea>
            </div>
            <div className='b2s'>
                <img onClick={this.previous} alt="previous" className='arrow leftarrow' src="https://influenced.link/static/lwquiz/whitearrow.png"></img>
                <img onClick={this.next} className='arrow' alt="next" src="https://influenced.link/static/lwquiz/whitearrow.png"></img>
            </div>
        </div>)
    }
}
export default Q3