window.LOCATIONS = ['mesa', 'gilbert', 'scottsdale', 'ahwatukee']
window.MesaMinistries = ['Cultivate', 'Band of Brothers', 'Extraordinary Sisters', 'Jesus Chicks', 'Jubilee', 'Servicio de Espanol', 'Champion Marriage', 'Celebrate Recovery']
window.ScottsdaleMinistries = ['Extraordinary Sisters']
window.GilbertMinistries = ['Worship Night', 'Band of Brothers', 'Extraordinary Sisters', 'Prayer']
window.AhwatukeeMinistries = ['Women\'s Ministries', 'Men\'s Ministries']
window.Conferences = ['Marriage', 'Men\'s Conference', 'Women\'s Conference', 'Genesis Conference', 'Parenting Conference', 'Leadership Conference']
window.MesaServices = [{
    day: 'Sunday',
    times: ['8:30AM', '9:30AM', '10:45AM', '12PM', '5PM']
}, {
    day: 'Wednesday',
    times: ['6:30PM']
}, {
    day: 'Saturday',
    times: ['6PM']
}]
window.GilbertServices = [{
    day: 'Sunday',
    times: ['9:30AM', '10:45AM']
}]
window.AhwatukeeServices = [{
    day: 'Sunday',
    times: ['8:30AM', '9:45AM', '11AM']
}]
window.ScottsdaleServices = [{
    day: 'Sunday',
    times: ['9:30AM', '10:45AM']
}]