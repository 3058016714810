import React, {Component} from 'react'
class Q2 extends Component {
    constructor(){
        super()
        this.state = {
            location: '',
            ministries: [],
            minImprove: ''
        }
    }
    previous = () => {
        window.APP.setState({place: 'q1'})
    }
    next = () => {
        window.APP.setState({place: 'q3'})
    }
    componentDidMount(){
        this.setState({location: this.props.location, ministries: this.props.ministries, minImprove: this.props.minImprove})
    }
    componentDidUpdate(){
        if (this.state.location !== this.props.location) this.setState({location: this.props.location})
        if (this.state.minImprove !== this.props.minImprove) this.setState({minImprove: this.props.minImprove})
        if (this.state.ministries.length !== this.props.ministries.length) this.setState({ministries: this.props.ministries})
    }
    ministry = (e) => {
        let ministry = e.target.value
        if (!this.state.ministries.includes(ministry)) {
            window.APP.setState({ministries: [...window.APP.state.ministries, ministry]})
        } else {
            let min = [...window.APP.state.ministries].filter(m => m !== ministry)
            window.APP.setState({ministries: min})
        }
    }
    minImprove = (e) => {
        window.APP.setState({minImprove: e.target.value})
    }
    render(){
        let q = 'question ' + this.props.place
        let a = this.state.location === 'mesa' ? window.MesaMinistries.map((m, i) => {
            let g = this.state.ministries.includes(m) ? true : false
            return (<div key={i} className='b1'>
                <p className="caps">{m}</p>
                <input type="checkbox" value={m} onChange={this.ministry} defaultChecked={g}></input>
            </div>)
        }) : this.state.location === 'gilbert' ? window.GilbertMinistries.map((m, i) => {
            let g = this.state.ministries.includes(m) ? true : false
            return (<div key={i} className='b1'>
                <p className="caps">{m}</p>
                <input type="checkbox" value={m} onChange={this.ministry} defaultChecked={g}></input>
            </div>)
        }) : this.state.location === 'ahwatukee' ? window.AhwatukeeMinistries.map((m, i) => {
            let g = this.state.ministries.includes(m) ? true : false
            return (<div key={i} className='b1'>
                <p className="caps">{m}</p>
                <input type="checkbox" value={m} onChange={this.ministry} defaultChecked={g}></input>
            </div>)
        }) : this.state.location === 'scottsdale' ? window.ScottsdaleMinistries.map((m, i) => {
            let g = this.state.ministries.includes(m) ? true : false
            return (<div key={i} className='b1'>
                <p className="caps">{m}</p>
                <input type="checkbox" value={m} onChange={this.ministry} defaultChecked={g}></input>
            </div>)
        }): ''
        if (!a && window.APP.state.place === 'q2') {
            window.APP.setState({place: 'q1'})
        }
        return (<div id="q2" className={q}>
            <div className="b1">
                <h1>Question 2</h1>
                <p>Which ministries do you go to?</p>
                <div className="b2 wrap">{a}</div>
            </div>
            <div className="b1">
                <p>What improvements would you like to see in the ministries you go to?</p>
                <textarea value={this.state.minImprove} onChange={this.minImprove}></textarea>
            </div>
            <div className="b2s">
                <img onClick={this.previous} className='arrow leftarrow' alt="previous" src="https://influenced.link/static/lwquiz/whitearrow.png"></img>
                <img onClick={this.next} className='arrow' alt="next" src="https://influenced.link/static/lwquiz/whitearrow.png"></img>
            </div>
        </div>)
    }
}
export default Q2