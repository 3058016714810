import React, {Component} from 'react'
class Q1 extends Component {
    constructor(){
        super()
        this.state = {
            location: null,
            favServPart: '',
            services: []
        }
    }
    previous = () => {
        window.APP.setState({place: 'home'})
    }
    next = () => {
        if (window.APP.state.location) {
            window.APP.setState({place: 'q2'})
        }
    }
    locationChoice = (e) => {
        const id = e.target.parentElement.id
        let locs = Array.from(document.querySelectorAll('.locRad'))
        locs.forEach(loc => {
            if (loc?.parentElement?.id !== id) {
                loc.checked = false
            }
        })
        window.APP.setState({location: id, services: []})
        let a = Array.from(document.querySelectorAll('.servebox'))
        if (a && a.length > 0) {
            a.forEach(ar => {
                ar.checked = false
            })
        }
    }
    favPart = (e) => {
        window.APP.setState({favServPart: e.target.value})
    }
    service = (e) => {
        let s = [...this.state.services]
        console.log(s)
        let val = e.target.value
        if (e.target.checked === true) {
            if (!s.includes(val)) {
                s.push(val)
            }
        } else {
            if (s.includes(val)) {
                for (let i = 0; i < s.length; i++) {
                    if (s[i] === val) {let o = s.splice(i, 1)}
                }
            }
        }
        console.log(s)
        window.APP.setState({services: s})
    }
    componentDidUpdate(){
        if (this.state.location !== this.props.location) this.setState({location: this.props.location})
        if (this.state.favServPart !== this.props.favServPart) this.setState({favServPart: this.props.favServPart})
        let a = () => this.setState({services: this.props.services})
        if (this.props.services.length !== this.state.services.length) a()
    }
    componentDidMount(){
        this.setState({location: this.props.location, favServPart: this.props.favServPart, services: this.props.services})
    }
    render(){
        let a = this.state.location === 'mesa' ? window.MesaServices.map((u, i) => {
            return (<div key={i} className="b1 w">
              <p className="caps green">{u.day}</p>
              <div className="b2">
                  {u.times.map((t, z) => {
                      return (<div key={z} className='b1'>
                          <p className='green'>{t}</p>
                          <input className="servebox" type="checkbox" value={t} onChange={this.service}></input>
                      </div>)
                  })}
              </div>
            </div>)
        }) : this.state.location === 'scottsdale' ? window.ScottsdaleServices.map((u, i) => {
            return (<div key={i} id={u.day} className="b1 w">
              <p className="caps green">{u.day}</p>
              <div className="b2">
                  {u.times.map((t, z) => {
                      return (<div key={z} className='b1'>
                          <p className='green'>{t}</p>
                          <input className="servebox" type="checkbox" value={t} onChange={this.service}></input>
                      </div>)
                  })}
              </div>
            </div>)
        }) : this.state.location === 'ahwatukee' ? window.AhwatukeeServices.map((u, i) => {
            return (<div key={i} id={u.day} className="b1 w">
              <p className="caps green">{u.day}</p>
              <div className="b2">
                  {u.times.map((t, z) => {
                      return (<div key={z} className='b1'>
                          <p className='green'>{t}</p>
                          <input className="servebox" type="checkbox" value={t} onChange={this.service}></input>
                      </div>)
                  })}
              </div>
            </div>)
        }) : this.state.location === 'gilbert' ? window.GilbertServices.map((u, i) => {
            return (<div key={i} id={u.day} className="b1 w">
              <p className="caps green">{u.day}</p>
              <div className="b2">
                  {u.times.map((t, z) => {
                      return (<div key={z} className='b1'>
                          <p className='green'>{t}</p>
                          <input className="servebox" type="checkbox" value={t} onChange={this.service}></input>
                      </div>)
                  })}
              </div>
            </div>)
        }) : ''
        let q = 'question ' + this.props.place
        return (<div id="q1" className={q}>
            <div className="b1">
                <h1 className="green">Question 1</h1>
            </div>
            <div className="b1">
                <p>Which location do you primarily attend?</p>
                <div id="q1s1" className="b2 wrap">
                    {window.LOCATIONS.map((l, i) => {
                        let g
                        return (<div key={i} id={l} className="b1">
                            <p className="green" style={{textTransform: "uppercase"}}>{l}</p>
                            <input className="locRad" type="radio" value={g} onChange={this.locationChoice}></input>
                        </div>)
                    })}
                </div>
            </div>
            <div className={'b1 ' + (a ? '': 'hidden')}>
                    <p className='green'>Which services do you typically attend?</p>
                    {a}
            </div>
            <div className={'b1 ' + (a ? '' : 'hidden')}>
                <p className='green'>What is your favorite part of service? Why?</p>
                <textarea></textarea>
            </div>
            <div className='b2s'>
                <img onClick={this.previous} alt="previous" className='arrow leftarrow' src="https://influenced.link/static/lwquiz/greenarrow.png"></img>
                <img onClick={this.next} className='arrow' alt="next" src="https://influenced.link/static/lwquiz/greenarrow.png"></img>
            </div>
        </div>)
    }
}
export default Q1